import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Row, Col, Breadcrumb, Divider } from 'antd';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { isEmpty } from 'lodash';
import DOMPurify from 'isomorphic-dompurify';

import { TitleRow, TitleCol, StyledContainer } from './styles';

import PublicLayout from '../../layout/public-layout';
import { StyledImage, StyledP, StyledDiv } from '../../templates/basic-styles';
import { NavigationWrapper } from '../../components/common/page-header';
import { formatDate, formatDateRangeToMonthDayDayYear, getTimeZoneByName } from '../../utils/date';
import { programShortName } from '../../siteContent';
import { StyledH5 } from '../../components/home/section-styles';
import { Button } from '../../components';
import { useI18NContext } from '../../i18n';

const EventLocation = styled(StyledP)`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    margin: 1rem 8px 0 0;
    max-width: 120px;
    width: 100%;

    && {
      font-size: 14px;
    }
  }
`;

const StyledImg = styled.img`
  width: 200px;
  height: auto;
  margin: 1rem;
`;

const StyledSponsorRow = styled(Row)`
  margin: 1rem -1rem 0;
`;

const StyledSponsorP = styled(StyledP)`
  font-size: 1.2rem;
  margin: 2rem 0 0;
`;

const StyledDescription = styled(StyledDiv)`
  & > p {
    padding-bottom: 16px;
  }
`;

export default function EventCalendarDetail({ location }: RouteComponentProps<any>) {
  const { allEvents, allSponsors } = useStaticQuery(graphql`
    query {
      allEvents: allDirectusEvent {
        nodes {
          directusId
          slug
          id
          translations {
            language
            name
            description
            cta_label
            event_sponsors_section_title
          }
          start_date_time
          end_date_time
          event_timezone {
            timezone_value
          }
          location
          public
          eventbrite_link
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            id
          }
        }
      }
      allSponsors: allDirectusEventCalendarSponsor {
        nodes {
          id
          translations {
            language
            type
          }
          event {
            directusId
          }
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const urlPath = location?.pathname;
  const transformed = urlPath?.split('/');
  const current = transformed?.pop();
  const filteredList = allEvents.nodes && allEvents.nodes.filter((x: any) => x.slug === `/${current}`);
  const event = filteredList[0];
  const directusTimezone = getTimeZoneByName(event?.event_timezone?.timezone_value)?.abbr || '';
  const sponsors =
    !isEmpty(filteredList) &&
    allSponsors.nodes.filter((s: any) => s?.event?.directusId.toString() === event.directusId.toString());

  const { translate, translateSlug } = useI18NContext();

  const sponsorRows = !isEmpty(sponsors)
    ? (sponsors as any[]).reduce((rows, sponsor = {}) => {
        const { type } = translate(sponsor);
        return {
          ...rows,
          [type]: [...(rows[type] || []), sponsor],
        };
      }, {})
    : {};
  const sponsorTitles = Object.keys(sponsorRows);

  const generateBreadcrumb = React.useCallback(
    (name?: string) => {
      return [
        <Link key="1" to={translateSlug('/event-calendar')}>
          {programShortName} Event Calendar
        </Link>,
        name,
      ];
    },
    [translateSlug]
  );

  return event ? (
    <PublicLayout seoTitle="Event Calendar">
      <StyledContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(translate(event).name).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <Row>
          <Col xs={24} sm={24} md={12} lg={8}>
            <StyledImage
              src={event.image.localFile !== null ? event.image.localFile.childImageSharp.fluid.src : ''}
              alt={event.name}
            />
          </Col>
          <Col xs={24} sm={24} md={11} lg={16}>
            <div style={{ marginLeft: '15px' }}>
              <TitleRow>
                <TitleCol>{translate(event).name}</TitleCol>
              </TitleRow>
              <EventLocation>
                {event.location && event.location.includes('http') ? (
                  <a
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={event.location}
                  >
                    Online Event
                  </a>
                ) : (
                  event.location
                )}
              </EventLocation>
              <StyledH5>
                {formatDateRangeToMonthDayDayYear(event.start_date_time, event.end_date_time, { month: 'MMM' })}
              </StyledH5>
              <StyledP>
                {`${formatDate(event.start_date_time, 'hh:mma')} - ${formatDate(
                  event.end_date_time,
                  'hh:mma'
                )} ${directusTimezone}`}
              </StyledP>
              {event.public && (
                <StyledButton type="primary-blue">
                  <a href={event.eventbrite_link} target="_blank" rel="noopener noreferrer">
                    {translate(event).cta_label}
                  </a>
                </StyledButton>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledDescription
              style={{ marginTop: '16px' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(translate(event).description),
              }}
            />
          </Col>
        </Row>
        <Divider />
        {!isEmpty(sponsorTitles) ? (
          <Row>
            <TitleRow>
              <TitleCol>{translate(event).event_sponsors_section_title}</TitleCol>
              {sponsorTitles.map((title: any, index: number) => {
                const row = sponsorRows[title];
                return (
                  <div key={index}>
                    <StyledSponsorP>{title}</StyledSponsorP>
                    <StyledSponsorRow align="middle">
                      {row.map((sponsor: any) => (
                        <StyledImg
                          key={sponsor.id}
                          src={sponsor.logo.localFile !== null ? sponsor.logo.localFile.childImageSharp.fluid.src : ''}
                          alt={sponsor.type}
                        />
                      ))}
                    </StyledSponsorRow>
                  </div>
                );
              })}
            </TitleRow>
          </Row>
        ) : (
          <div />
        )}
      </StyledContainer>
    </PublicLayout>
  ) : (
    <div />
  );
}
